import React from "react";
import { Wrapper } from "../style/Grid";
import { Helmet } from "react-helmet";
import { HOTLINE } from "../components/header";
import LayoutMinimum from "../components/layoutMinimum";
import { getCommodityFromHash } from "../helpers";
import ContactForm from "../components/contactForm";
import StepNavigation from "../components/StepNavigation";
import { CONTRACT_SOURCE_ID_INTERESTED_IN_OFFER } from "../constants";

const navigationSteps = [
  { name: "Váš telefon", label: "1", href: "#", status: "current" },
  { name: "Děkujeme", label: "2", href: "#", status: "next" },
];

const Lead = () => {
  return (
    <LayoutMinimum type={HOTLINE} text={"Zájem o nabídku energií od MND"}>
      <Helmet>
        <title>Zájem o nabídku energií od MND</title>
        <meta name="robots" content="noindex" />
        <link rel="canonical" href="https://www.mnd.cz/zajem-o-nabidku-1"></link>
      </Helmet>

      <Wrapper background={["#D6D6D6"]} p={["30px 20px", "60px 10px 40px"]}>
        <StepNavigation steps={navigationSteps} />
      </Wrapper>

      <Wrapper background={["white.100"]} p={["30px 20px", "60px 10px 40px"]}>
        <ContactForm
          leadsource={CONTRACT_SOURCE_ID_INTERESTED_IN_OFFER}
          analytics={{
            category: "Zájem o nabídku",
            sourceId: CONTRACT_SOURCE_ID_INTERESTED_IN_OFFER,
            type: 'Lead',
            section: 'Product',
          }}
          enableCallbackTime={true}
          enableEmail={false}
          type="OFFER"
          commodity={getCommodityFromHash(typeof document !== "undefined" ? document.location.hash : "")}
          redirectOnSuccess={"/zajem-o-nabidku-2"}
        />
      </Wrapper>
    </LayoutMinimum>
  );
};

export default Lead;
